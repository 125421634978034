/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.string.ends-with.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import WeekCalendar from '@/components/WeekCalendar/WeekCalendar';
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'Attendances',
  components: {
    WeekCalendar: WeekCalendar,
    Scroll: Scroll
  },
  data: function data() {
    return {
      list: [],
      dayList: [],
      currDate: '',
      startDate: '',
      userSerial: '',
      showFlag: true,
      todayDate: {},
      selectMonth: '',
      selectDay: '',
      monthDetail: {},
      //出勤数据
      popFlag: false,
      popStyle: {
        top: '0px',
        // right: '0px',
        right: '0.8rem'
      },
      pullDownRefresh: {
        threshold: 60,
        // 下拉多少开始刷新
        stop: 40 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false
    };
  },
  methods: {
    // 下拉刷新
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      var date = this.$utils.DateUtils.getFullDate(this.startDate);
      this.getAttenceRecord(date);
    },
    onPullingUp: function onPullingUp() {
      if (this.isPullUp) return;
      this.isPullUp = true;
      var date = this.list[this.list.length - 1].date;

      if (date.endsWith('01')) {
        this.$refs.scroll.forceUpdate();
        return;
      }

      var selectDate = this.$utils.DateUtils.addDate(date, -1);
      this.getAttenceRecord(selectDate);
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll() {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate();
    },
    popShow: function popShow() {
      this.popFlag = false;
    },
    dayClick: function dayClick(date) {
      this.showFlag = true;
      this.$utils.Tools.loadShow();
      this.list = [];
      this.startDate = date;
      this.getAttenceRecord(date);
      this.selectMonth = this.$utils.DateUtils.getMonth(date);

      if (this.selectMonth < 10) {
        this.selectMonth = '0' + this.selectMonth;
      }

      this.selectDay = date;
    },
    monthClick: function monthClick(date) {
      this.startDate = this.$utils.DateUtils.getMonthEnd(date);
      var temp = this.$utils.DateUtils.getMonthEnd(this.currDate);

      if (this.startDate === temp) {
        this.startDate = this.currDate;
      }

      var month1 = this.$utils.DateUtils.formatDateMonth(date);
      var month2 = this.$utils.DateUtils.formatDateMonth(this.currDate);
      this.showFlag = month1 === month2;
      this.$utils.Tools.loadShow();
      this.list = [];
      this.getAttenceRecord(this.startDate);
      this.getAttenceSum(date);
      this.selectMonth = this.$utils.DateUtils.getMonth(date);

      if (this.selectMonth < 10) {
        this.selectMonth = '0' + this.selectMonth;
      }

      this.selectDay = date;
    },
    changTitle: function changTitle() {
      this.showFlag = !this.showFlag;
    },
    onShowDetail: function onShowDetail(index) {
      this.$router.push({
        name: 'AttendDetail',
        params: {
          index: index,
          date: this.selectDay
        }
      });
    },
    getAttenceRecord: function getAttenceRecord(date) {
      var _this = this;

      this.$api.Attence.getAttenceRecord({
        userSerial: this.userSerial,
        dates: date,
        flag: '32'
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.updateScroll(false);
        } else {
          var _this$list;

          //将打卡信息存入list
          (_this$list = _this.list).push.apply(_this$list, _toConsumableArray(data));

          if (_this.list.length === 0) {
            _this.emptyShow = true;
          } else {
            _this.todayDate = _this.list[0];
          }

          _this.updateScroll();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getAttenceDayRecord: function getAttenceDayRecord(date, type, event) {
      var _this2 = this;

      if (+type !== 1) {
        return;
      }

      this.popFlag = false;
      this.$utils.Tools.loadShow();
      this.$api.Attence.getAttenceDayRecord({
        userSerial: this.userSerial,
        dates: date,
        flag: '1'
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.dayList = data;
          _this2.popFlag = true;
          setTimeout(function () {
            _this2.popStyle.top = event.clientY - _this2.$refs.pop.offsetHeight + 'px'; // this.popStyle.left = event.clientX + "px";
          }, 1);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getAttenceSum: function getAttenceSum(date) {
      var _this3 = this;

      this.$api.Attence.getAttenceSum({
        userSerial: this.userSerial,
        dates: date
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.monthDetail = data[0];
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this4 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.currDate = data.sysDate;
          _this4.startDate = data.sysDate;
          _this4.selectMonth = _this4.$utils.DateUtils.getMonth(_this4.startDate);

          if (_this4.selectMonth < 10) {
            _this4.selectMonth = '0' + _this4.selectMonth;
          }

          _this4.selectDay = _this4.$utils.DateUtils.getFullDate(_this4.startDate);

          var date = _this4.$utils.DateUtils.getFullDate(_this4.startDate);

          _this4.getAttenceRecord(date);

          _this4.getAttenceSum(date);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem('userSerial');
  },
  mounted: function mounted() {
    var date = this.$utils.Store.getItem('attendDate');

    if (date !== null) {
      this.startDate = date + ' 00:00:00';
      this.selectMonth = this.$utils.DateUtils.getMonth(this.startDate);

      if (this.selectMonth < 10) {
        this.selectMonth = '0' + this.selectMonth;
      }

      this.selectDay = this.$utils.DateUtils.getFullDate(this.startDate);
      this.monthClick(date);
    } else {
      this.getSysDate();
    }

    if (this.$route.params.notification) {
      this.pushHistory();
    }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('attendDate');
  }
};